import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from '../components';
import MainLayout from './MainLayout';

const ProtectedLayout: FC = () => (
  <MainLayout>
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  </MainLayout>
);

export default ProtectedLayout;
