import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Module, ModuleState, Submodule, SubmoduleFilter } from './types/modules';

const initialState: ModuleState = {
  modules: [],
  loadCount: 0,
  adminFetchCount: 0,
};

export const ModulesData = createSlice({
  name: 'Modules',
  initialState,
  reducers: {
    setAuthorisedModule: (state, action: PayloadAction<Module[]>) => {
      state.modules = [...action.payload];
    },
    resetAuthorisedModule: () => initialState,
    setAuthorisedSubModule: (state, action: PayloadAction<{ module_id: string, sub_modules: Submodule[] }>) => {
      const { module_id, sub_modules } = action.payload;

      const module = state.modules.find(m => m.id === module_id);
      if (module) {
        module.submodules = [...sub_modules];
      }
    },
    setSubModuleFilterOptions: (state, action: PayloadAction<{ module_id: string, sub_module_id: string, filter_options: SubmoduleFilter[] }>) => {
      const { module_id, sub_module_id, filter_options } = action.payload;

      const module = state.modules.find(m => m.id === module_id);
      if (module) {
        const submodule = module.submodules?.find(s => s.id === sub_module_id);
        if (submodule) {
          submodule.filters = [...filter_options];
        }
      }
    },
    resetAuthorisedSubModule: (state, action: PayloadAction<{ module_id: string }>) => {
      const { module_id } = action.payload;

      const module = state.modules.find(m => m.id === module_id);
      if (module) {
        module.submodules = [];
      }
    },
    setLoadCount: (state) => {
      state.loadCount += 1;
    },
    setSelectedSubmoduleFilters: (
      state,
      action: PayloadAction<{
        moduleName: string;
        subModuleName: string;
        filterOptions: { type: string; options: { id: string }[] }[];
      }>
    ) => {
      const { moduleName, subModuleName, filterOptions } = action.payload;

      // Extract the ids of all options in the filterOptions array
      const selectedIds = filterOptions.flatMap(option =>
        option.options.map(opt => opt.id)
      );

      // Find the matching module and submodule
      const module = state.modules.find(m => m.name === moduleName);
      if (module && module?.submodules) {
        const subModule = module.submodules.find(sub => sub.name === subModuleName);
        if (subModule && subModule.filters) {
          // Add the isSelected property to matching filters
          subModule.filters.forEach(filter => {
            filter.isSelected = selectedIds.includes(filter.id);
          });
        }
      }
    },
    setAdminFetchCount: (state) => {
      state.adminFetchCount += 1;
    },
    resetFilterSelectionData: (state) => {
      state.modules = state.modules.map(module => ({
        ...module,
        submodules: module.submodules?.map(submodule => ({
          ...submodule,
          filters: submodule.filters?.map(filter => ({
            ...filter,
            isSelected: false,
          })) || [],
        })) || [],
      }));
    }
  }
});

export const {
  setAuthorisedModule,
  resetAuthorisedModule,
  setAuthorisedSubModule,
  resetAuthorisedSubModule,
  setSubModuleFilterOptions,
  setLoadCount,
  setSelectedSubmoduleFilters,
  setAdminFetchCount,
  resetFilterSelectionData
} = ModulesData.actions;

export default ModulesData.reducer;
