import { NAV_ROUTES } from "../../constants/navRoutes";

const LOCATION = {
  "user-management": {
    label: "Users",
    type: "initial",
    location: NAV_ROUTES.USER_MANAGEMENT,
  },
  "user-invite": {
    label: "Invite User",
    location: '',
  },
  "user-details": {
    label: "Details",
    location: '',
  },
  "user-edit": {
    label: "Edit User",
    location: '',
  },
} as any;

export default LOCATION;
