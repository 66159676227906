import { useDispatch } from 'react-redux';
import { useLazyGetUserModulesByIdQuery } from '../api/userModules';
import { formatUserModuleList } from '../utils/common';
import { setUserModuleData } from '../stores/SelectedUser/slice';

const useUserModulesById = () => {
  const [getUserModulesById, { isLoading, isError }] = useLazyGetUserModulesByIdQuery();

  const dispatch = useDispatch();

  const getUserModuleDetailsById = async (id: string) => {
    try {
      const response = await getUserModulesById(id).unwrap();

      if (response.data) {
        const data = formatUserModuleList(response.data);
        dispatch(setUserModuleData(data));

        return response;
      }
    } catch (err) {
      console.error('Error while fetching user module details by id :', err);
    }
  };

  return { getUserModuleDetailsById, isLoading, isError };
};

export default useUserModulesById;
