import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/material';
import loader from '../../assets/images/Loader.json';

const FullPageLoader: React.FC = () => {
    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box sx={{ width: '15%' }}>
                <Player
                    src={loader}
                    className="player"
                    loop
                    autoplay
                />
            </Box>
        </Box>
    );
};

export default FullPageLoader;
