const NAV_ROUTES = {
    AUTH: '/auth',
    AUTH_REDIRECT: '/callback',
    USER_MANAGEMENT: '/user-management',
    USER_MANAGEMENT_INVITE: '/user-management/user-invite',
    USER_MANAGEMENT_DETAILS: '/user-management/user-details/:id',
    DASHBOARD: '/dashboard',
    DASHBOARD_MODULE: '/dashboard/:module',
    DASHBOARD_MODULE_SUBMODULE: '/dashboard/:module/:submodule',
    HOME: '/home',
    ERROR: '/server-error',
    PAGE_NOT_FOUND: '/page-not-found'
};

export  { NAV_ROUTES };
  