import { createSlice } from '@reduxjs/toolkit';
import { ApiError } from './types/api';
import { AppState, Auth } from './types/app';

const initialState: AppState = {
  apiErrorLog: {},
  auth: {
    access_token: '',
    refresh_token: ''
  },
  toast: {
    toast_message: ''
  }
};

export const AppData = createSlice({
  name: 'AppData',
  initialState,
  reducers: {
    setApiErrorLog: (state: AppState, { payload }: { payload: ApiError }) => {
      const data = state?.apiErrorLog
        ? { ...state.apiErrorLog, [payload.endpoint]: payload }
        : { [payload.endpoint]: payload };

      return { ...state, apiErrorLog: data };
    },
    unsetApiErrorLog: (state) => ({ ...state, apiErrorLog: {} }),
    setAuthToken: (state: AppState, { payload }: { payload: Auth }) => {
      return { ...state, auth: { ...payload } }
    },
    resetAppData: () => initialState,
    setToastMessage: (state: AppState, { payload }: { payload: string }) => {
      return { ...state, toast: { toast_message: payload } }
    },
    resetToastMessage: (state: AppState) => {
      return { ...state, toast: { toast_message: '' } }
    },
  }
});

export const {
  setAuthToken,
  setApiErrorLog,
  unsetApiErrorLog,
  resetAppData,
  setToastMessage,
  resetToastMessage
} = AppData.actions;

export default AppData.reducer;
