import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MainLayout } from '../../layouts';
import { FullPageLoader } from '../../components';
import { RootState } from '../../stores/store';
import { NAV_ROUTES } from '../../constants/navRoutes';
import UserAuth from '../../services/auth';

const HomePage: FC = () => {
  const navigate = useNavigate();
  const appData = useSelector((state: RootState) => state?.AppData);

  const token = appData?.auth?.access_token || UserAuth.accessToken || '';

  // Hook to redirect valid user to auth or dashboard page
  useEffect(() => {
    if (token) {
      navigate(NAV_ROUTES.DASHBOARD);
    } else {
      navigate(NAV_ROUTES.AUTH);
    }
  }, [token]);

  return (
    <MainLayout>
      <FullPageLoader />
    </ MainLayout >
  );
}

export default HomePage;
