import { UserPayload } from '../api/company';
import { useInviteUserMutation } from '../api/userList';
import useUserList from './useUserList';

const useInviteUser = () => {
  const [inviteUser] = useInviteUserMutation();
  const { getUserList } = useUserList();

  const createUser = async (payload: UserPayload) => {
    try {
      const response = await inviteUser(payload).unwrap();

      if (response.data) {
        getUserList({});
      }
      return response;
    } catch (err) {
      console.error('Error while creating user :', err);
    }
  };

  return { createUser };
};

export default useInviteUser;
