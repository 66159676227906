import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';

export interface UserSubmoduleMap {
  moduleId?: string;
  submoduleId: string;
  filterIds: string[];
};

export interface UserPayload {
  id?: string;
  email: string;
  userRole: string;
  username: string;
  companyId: string;
  submoduleList: UserSubmoduleMap[];
}

const company = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<any, any>({
      query: () => `${apiRoutes.companies}`
    }),
    createCompany: builder.mutation<any, any>({
      query: ({ name }: { name: string }) => ({
        url: `${apiRoutes.companies}`,
        method: 'POST',
        body: { name }
      }),
      invalidatesTags: ['companyList']
    })
  })
});

export const { useLazyGetCompaniesQuery, useCreateCompanyMutation } = company;
