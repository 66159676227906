import { useEffect, useState } from "react";
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ModuleRow from "./ModuleRow";
import { titles, messages } from "../../../constants/constants";
import { UserSubmoduleMap } from "../../../api/company";

interface ModuleDetailsSectionProps {
  onUpdateData: any,
  moduleList: UserSubmoduleMap[],
  handleUpdatedSubModuleIds: any
};

const ModuleDetailsSection = ({
  onUpdateData,
  moduleList,
  handleUpdatedSubModuleIds
}: ModuleDetailsSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [moduleDetails, setModuleDetails] = useState<UserSubmoduleMap[]>([]);

  const addNewModule = () => {
    setModuleDetails([
      ...moduleDetails,
      { moduleId: "", submoduleId: "", filterIds: [] },
    ]);
  };

  const onDeleteModule = (index: number) => {
    const modules = [...moduleDetails];
    modules.splice(index, 1);
    setModuleDetails(modules);
  };

  const onUpdateModule = (index: number, value: UserSubmoduleMap) => {
    const modules = [...moduleDetails];
    modules[index] = value;
    setModuleDetails(modules);
  };

  // Hook to initialize moduleDetails on load
  useEffect(() => {
    if (moduleList?.length && !moduleDetails?.length) {
      setModuleDetails(moduleList);
    }
  }, [moduleList]);

  useEffect(() => {
    const submoduleList = moduleDetails.map((mod) => ({
      submoduleId: mod.submoduleId,
      filterIds: mod.filterIds
    }));
    onUpdateData('submoduleList', submoduleList);
  }, [moduleDetails]);

  return (
    <Box sx={{ mt: 3.5 }}>
      <Typography
        sx={{
          color: "#6B7280",
          fontSize: 16,
          fontWeight: 500,
        }}
      >
        {titles.MODULE_DETAILS}
      </Typography>
      {moduleDetails?.length > 0 && (
        <Box sx={{ mt: 2.5, display: "flex", flexDirection: "column", gap: 2 }}>
          {moduleDetails?.map((e, i) => (
            <>
              <ModuleRow
                item={e}
                index={i}
                key={`${e.moduleId}_${e.submoduleId}`}
                onDelete={() => onDeleteModule(i)}
                onUpdateData={(value: any) => onUpdateModule(i, value)}
                handleUpdatedSubModuleIds={handleUpdatedSubModuleIds}
              />
              {(isMobile && moduleDetails.length - 1 !== i) ? (
                <Divider sx={{ pt: 2, mb: 2 }} />
              ) : (
                <></>
              )}
            </>
          ))}
        </Box>
      )}
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom_button.primary,
          color: theme.palette.custom_button.primary_font,
          borderRadius: "100px",
          textTransform: "capitalize",
          mt: 3,
          ml: '1px',
          px: 4,
          py: 1,
          "&:hover": {
            backgroundColor: theme.palette.custom_button.secondary,
          },
        }}
        disabled={moduleDetails.length > 0 && !(moduleDetails[moduleDetails?.length - 1]?.submoduleId)}
        startIcon={<AddIcon />}
        onClick={addNewModule}
      >
        {messages.addNewModule}
      </Button>
    </Box>
  );
};

export default ModuleDetailsSection;
