import { FC } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as RadioGroupMUI,
  useTheme,
} from "@mui/material";

type RadioGroupProps = {
  label?: string;
  labelId?: string;
  options: Array<{ value: string; label: string }>;
  name: string;
  row?: boolean;
  value: string;
  handleChange: (value: string) => void;
};

const RadioGroup: FC<RadioGroupProps> = (props) => {
  const theme = useTheme();
  const { label, labelId, options, name, row = false, value, handleChange } = props;

  return (
    <FormControl>
      {label && <FormLabel id={labelId}>{label}</FormLabel>}
      <RadioGroupMUI
        aria-labelledby={labelId}
        name={name}
        row={row}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        sx={{ gap: 2 }}
      >
        {Array.from(new Set(options))?.map(({ label, value }) => (
          <FormControlLabel
            value={value}
            sx={{ gap: 2 }}
            key={label}
            control={
              <Radio
                sx={{
                  color: theme.palette.custom_checkbox.selected,
                  "&.Mui-checked": {
                    color: theme.palette.custom_checkbox.selected,
                  },
                }}
              />
            }
            label={label}
          />
        ))}
      </RadioGroupMUI>
    </FormControl>
  );
};

export default RadioGroup;
