import React from 'react';
import { Menu, MenuItem, useTheme } from '@mui/material';

interface MenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  onMenuItemClick: (action: string, id: string) => void;
  actions: string[];
  value?: any;
}

const CustomMenu: React.FC<MenuProps> = ({
  anchorEl,
  open,
  onClose,
  onMenuItemClick,
  actions,
  value
}) => {
  const theme = useTheme();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: theme.palette.custom_menu.secondary_background,
        },
      }}
    >
      {actions.map((action, index) => (
        <MenuItem
          key={`${action}_${value || index}`}
          onClick={() => {
            onMenuItemClick(action, value);
            onClose();
          }}
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.custom_menu.selected,
            },
            color: theme.palette.custom_menu.font,
          }}
        >
          {action}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CustomMenu;
