import { createSlice } from '@reduxjs/toolkit';
import { UserState, User } from './types/user';

const initialState: UserState = {
  user: {
    id: '',
    email: '',
    status: '',
    role: ''
  }
};

export const UserData = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state: UserState, { payload }: { payload: User }) => {
      return { ...state, user: payload };
    },
    resetUserData: () => initialState
  }
});

export const {
  setUser,
  resetUserData,
} = UserData.actions;

export default UserData.reducer;
