import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserAuth from '../../services/auth';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { RootState } from '../../stores/store';
import FullPageLoader from '../FullPageLoader';

interface AuthGuardProps {
  children?: React.ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const navigate = useNavigate();
  const appData = useSelector((state: RootState) => state?.AppData);
  const userDetails = useSelector((state: RootState) => state?.UserData);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const token = appData?.auth?.access_token || UserAuth.accessToken || '';

  // Hook to authenticate logged in user
  useEffect(() => {
    setAuthenticated(Boolean(token && userDetails?.user?.id));
  }, [userDetails, token]);

  // Hook to redirect user without token to auth page
  useEffect(() => {
    if (!token) {
      navigate(NAV_ROUTES.AUTH);
    }
  }, [token]);

  if (isAuthenticated) {
    return (<>{children}</>);
  }
  return (
    <FullPageLoader />
  );
};

export default AuthGuard;
