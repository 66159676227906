import dayjs from 'dayjs';
import { SideNavOptions } from 'src/components/SideNav/types';
import { Module, Submodule, SubmoduleFilter } from '../stores/Modules/types/modules';
import { User } from '../stores/User/types/user';
import { USER_ROLES, USER_STATUS } from '../constants/constants';
import { UserModulesApiMap, UserSubModulesFilterApiMap } from '../api/userModules';
import { UserSubmoduleMap } from '../api/company';

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return '-';
  }
  return dayjs(dateString).format('MMM D, YYYY');
};

export const convertModulesToOptions = (modules: Module[]): SideNavOptions[] => {
  return modules.map((module) => {
    const subOptions = module.submodules?.map((submodule: Submodule) => ({
      label: submodule.name,
      path: submodule.url
    }));

    return {
      label: module.name,
      path: module.submodules?.[0]?.url || '',
      icon: undefined,
      subOptions: subOptions
    };
  }) || [];
};

export const formatUserList = (users: User[]): User[] => {
  if (users?.length) {
    return users.map((user) => {
      return {
        id: user.id || '',
        email: user.email || '',
        status: USER_STATUS[user.status as keyof typeof USER_STATUS] || '',
        role: USER_ROLES[user.role as keyof typeof USER_ROLES] || '',
        joiningDate: formatDate(user.joiningDate || ''),
        companyId: user.companyId || ''
      };
    });
  }
  return [];
};

export const formatUserModuleList = (modulesFromApi: UserModulesApiMap[]): Module[] => {
  return modulesFromApi?.length
    ? modulesFromApi.map((module): Module => {
      const formattedModule: Module = {
        id: module.id,
        name: module.name,
        submodules: module.submoduleCatalogs.map((submodule): Submodule => {
          const formattedSubmodule: Submodule = {
            id: submodule.id,
            name: submodule.name,
            url: submodule.url,
            filters: formatUserSubmoduleFilters(submodule.submoduleFilters)
          };

          return formattedSubmodule;
        }),
      };

      return formattedModule;
    })
    : [];
};

export const formatUserSubmoduleFilters = (filtersFromApi: UserSubModulesFilterApiMap[]): SubmoduleFilter[] => {
  if (filtersFromApi?.length) {
    const submoduleFilters: SubmoduleFilter[] = [];

    filtersFromApi.forEach((submoduleFilter) =>
      submoduleFilter.submoduleFilterRoles.forEach((filterOption) => {
        submoduleFilters.push({
          id: filterOption.id,
          filterValue: filterOption.filterValue,
          submoduleFilterName: submoduleFilter.name,
          submoduleFilterId: submoduleFilter.id,
          isSelected: true,
        });
      })
    );

    return submoduleFilters;
  }
  return [];
};

export const formatModulesToSubmoduleList = (modules: Module[]): UserSubmoduleMap[] => {
  const submoduleFilters: UserSubmoduleMap[] = [];

  if (modules?.length) {
    modules.forEach((module) => {
      if (module.submodules?.length) {
        module.submodules.forEach((submodule) => {
          submoduleFilters.push({
            moduleId: module.id,
            submoduleId: submodule.id,
            filterIds: submodule.filters?.map((filter) => filter.id) || []
          });
        });
      }
    });
  }

  return submoduleFilters;
};

export const getSelectedFilterIds = (modules: Module[], submoduleId: string): string[] => {
  const selectedFilterIds: string[] = [];

  for (const module of modules) {
      if (module.submodules) {
          for (const submodule of module.submodules) {
              if (submodule.id === submoduleId) {
                  if (submodule.filters) {
                      const selectedFilters = submodule.filters.filter(filter => filter.isSelected);                      
                      selectedFilterIds.push(...selectedFilters.map(filter => filter.id));
                  }
              }
          }
      }
  }

  return selectedFilterIds;
}

export const getDefaultFilterForUrl = (modules: Module[], url: string): string | null => {
  for (const module of modules) {
      if (module.submodules) {
          for (const submodule of module.submodules) {
              if (submodule.url === url) {
                  return submodule.defaultFilter || null;
              }
          }
      }
  }
  return null;
}
