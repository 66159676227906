import { FC } from "react";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RadioGroup, SelectWithLabel } from "../../../components";
import { titles } from "../../../constants/constants";
import { Company } from "../../../stores/CompanyList/types/companyList";
import { UserPayload } from "../../../api/company";

type UserDetailsProp = {
  user?: UserPayload | null;
  companies?: Company[];
  isUserDetails?: boolean;
  toggleModuleSection?: (val: Boolean) => void;
  onUpdateData: (field: string, value: any) => void;
};
const UserDetailsSection: FC<UserDetailsProp> = (props) => {
  const { user, isUserDetails = false, toggleModuleSection, companies, onUpdateData } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getCompanyOptions = () => {
    return companies?.length ? companies.map((val) => ({ label: val.name, value: val.companyId })) : [];
  };

  return (
    <Box sx={{ mt: 3.5 }}>
      <Typography
        sx={{
          color: "#6B7280",
          fontSize: 16,
          fontWeight: 500,
        }}
      >
        {titles.USER_DETAILS}
      </Typography>
      <Box
        sx={{
          mt: 2.5,
          display: "grid",
          gap: 2,
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          width: "100%",
          mb: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <SelectWithLabel
            label="Company"
            labelId="company-select-label"
            menuItems={getCompanyOptions()}
            onChange={(value) => onUpdateData('companyId', value)}
            isAddNew={true}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                },
              },
            }}
            value={user?.companyId}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <TextField
              label="Email Id"
              id="email-select-label"
              value={user?.email}
              onChange={(e) => onUpdateData('email', e.target.value)}
              disabled={isUserDetails}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <TextField
              label="User Name"
              id="userName-select-label"
              value={user?.username}
              onChange={(e) => onUpdateData('username', e.target.value)}
            />
          </FormControl>
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        gap: 2,
        pt: isMobile ? 2 : 0,
        alignItems: "center",
        justifyContent: isMobile ? "center" : "flex-start",
        opacity: isUserDetails ? "0.5" : "1",
        pointerEvents: isUserDetails ? "none" : "cursor"
      }}>
        {/* {isUserDetails && (
          <Switch onValueChange={toggleModuleSection} defaultValue />
        )} */}
        <RadioGroup
          value={user?.userRole || "VIEWER"}
          options={[
            { value: "ADMIN", label: "Admin" },
            { value: "VIEWER", label: "Viewer" },
          ]}
          name="admin_viewer_radio"
          row
          handleChange={(value) => onUpdateData('userRole', value)}
        />
      </Box>
    </Box>
  );
};

export default UserDetailsSection;
