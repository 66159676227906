import { FC, MouseEventHandler, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputLabel, MenuItem, Select, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, SelectProps, useTheme } from "@mui/material";
import useCreateCompany from "../../hooks/useCreateCompany";

type CustomSelectProps = {
  label: string;
  labelId: string;
  menuItems?: Array<{ label: string; value: string }>;
  Icon?: JSX.Element;
  defaultValue?: string;
  onClick?: MouseEventHandler;
  onChange?: (value: any) => void;
  isAddNew?: boolean;
  disabled?: boolean;
};

const SelectWithLabel: FC<CustomSelectProps & SelectProps> = (props) => {
  const {
    label,
    labelId,
    menuItems,
    Icon = <ArrowDropDownIcon />,
    defaultValue,
    onClick = () => { },
    onChange = () => { },
    isAddNew,
    value,
    disabled = false,
    ...rest
  } = props;

  const theme = useTheme();
  const { addCompany } = useCreateCompany();

  const [openDialog, setOpenDialog] = useState(false);
  const [newValue, setNewValue] = useState("");

  const handleAddNewClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogSubmit = async () => {
    if (newValue) {
      const res = await addCompany({ name: newValue });
      onChange(res.id);
      setNewValue(res.name);
    }
    setOpenDialog(false);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel
          id={labelId}
        >
          {label}
        </InputLabel>
        <Select
          {...rest}
          label={label}
          labelId={labelId}
          onClick={onClick}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          inputProps={{
            MenuProps: {
              sx: {
                maxHeight: '20rem',
                '& .MuiMenu-list': {
                  backgroundColor: theme.palette.custom_field_background,
                  color: theme.palette.custom_menu.font,
                  padding: 0
                },
              }
            },
          }}
          {...((Icon && { IconComponent: () => Icon }) || {})}
        >

          {isAddNew && (
            <MenuItem
              onClick={handleAddNewClick}
              sx={{
                color: '#0099FF',
                py: 2,
                '&:hover': {
                  backgroundColor: theme.palette.custom_menu.selected,
                },
              }}
            >
              + Create New
            </MenuItem>
          )}
          {menuItems?.map(({ label, value }) => (
            <MenuItem
              key={value}
              value={value}
              onClick={() => onChange(value)}
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.custom_menu.selected,
                },
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '35%',
            padding: 2
          },
        }}
      >
        <DialogTitle>Add Company</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Company Name"
            type="text"
            fullWidth
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            px: 2
          }}
        >
          <Button
            variant="outlined"
            sx={{ borderRadius: "100px" }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ borderRadius: "100px" }}
            onClick={handleDialogSubmit}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectWithLabel;
