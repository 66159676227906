import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import backgroundImage from '../../assets/images/pvx-home-bg.png';
import { messages, titles } from '../../constants/constants';
import { NAV_ROUTES } from '../../constants/navRoutes';

const ErrorPage: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <p
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '50px',
          left: '50px',
        }}
        onClick={() => navigate(NAV_ROUTES.HOME)}
      >
        {messages.goToHome}
      </p>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#333333CC',
          textAlign: 'center'
        }}
      >
        <div>
          <h1>{titles.SOMETHING_WENT_WRONG}</h1>
          <p>{messages.pleaseWaitWhileWeFixIt}</p>
        </div>
      </Box>
    </>
  );
}

export default ErrorPage;
