import { createSlice } from '@reduxjs/toolkit';
import { CompanyListState, Company } from './types/companyList';

const initialState: CompanyListState = {
  companyList: []
};

export const CompanyListData = createSlice({
  name: 'CompanyList',
  initialState,
  reducers: {
    setCompanyList: (state: CompanyListState, { payload }: { payload: Company[] }) => {
      state.companyList = [...payload];
    },
    resetCompanyList: () => initialState
  }
});

export const {
  setCompanyList,
  resetCompanyList,
} = CompanyListData.actions;

export default CompanyListData.reducer;
