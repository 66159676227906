import { createSlice } from '@reduxjs/toolkit';
import { SelectedUserState, SelectedUser } from './types/selectedUser';
import { Module } from '../../stores/Modules/types/modules';

const initialState: SelectedUserState = {
  user: null
};

export const SelectedUserData = createSlice({
  name: 'SelectedUser',
  initialState,
  reducers: {
    setUserData: (state: SelectedUserState, { payload }: { payload: SelectedUser }) => {
      return { ...state, user: { ...state.user, ...payload } };
    },
    setUserModuleData: (state: SelectedUserState, { payload }: { payload: Module[] }) => {
      return { ...state, user: { ...state.user, modules: payload } };
    },
    resetUserData: () => {
      return initialState;
    }
  }
});

export const {
  setUserData,
  setUserModuleData,
  resetUserData,
} = SelectedUserData.actions;

export default SelectedUserData.reducer;
