import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';

const user = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<any, any>({
      query: () => `${apiRoutes.userDetails}`
    })
  })
});

export const { useLazyGetUserQuery } = user;
