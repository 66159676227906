import { useLazyGetUsersQuery } from '../api/userList';
import { useDispatch } from 'react-redux';
import { setUserList } from '../stores/UserList/slice';

const useUserList = () => {
  const [getUsers, { isLoading, isError }] = useLazyGetUsersQuery();

  const dispatch = useDispatch();

  const getUserList = async ({
    pageNumber = 0,
    pageSize = 10,
    search,
    sort,
    sortOrder
  }: {
    pageNumber?: number,
    pageSize?: number,
    search?: string,
    sort?: string,
    sortOrder?: 'ASC' | 'DESC';
  }) => {
    try {
      const response = await getUsers({
        pageNumber,
        pageSize,
        search,
        sort,
        sortOrder
      }).unwrap();

      if (response.data) {
        const data = {
          ...response.data,
          items: response.data.items.map((item: any) => ({
            id: item.id,
            username: item.name,
            email: item.email,
            role: item.role,
            status: item.status,
            joiningDate: item.joiningDate,
            companyId: item.company.id
          }))
        };
        dispatch(setUserList(data));
      }
    } catch (err) {
      console.error('Error while fetching users :', err);
    }
  };

  return { getUserList, isLoading, isError };
};

export default useUserList;
