import { useRetryCreateUserMutation } from '../api/userList';
import useUserList from './useUserList';

const useRetryCreateUser = () => {
  const [retryCreateUser] = useRetryCreateUserMutation();
  const { getUserList } = useUserList();

  const reCreateUser = async (payload: { id: string; }) => {
    try {
      const response = await retryCreateUser(payload).unwrap();

      if (response.code) {
        getUserList({});
      }
      return response;
    } catch (err) {
      console.error('Error while retry create user :', err);
    }
  };

  return { reCreateUser };
};

export default useRetryCreateUser;
