import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
  interface Palette {
    custom_background: string;
    custom_field_background: string;
    custom_sidebar: {
      primary: string;
      secondary: string;
      background: string;
      hover: string;
    };
    custom_button: {
      primary: string;
      secondary: string;
      primary_font: string;
      secondary_font: string;
    };
    custom_menu: {
      primary_background: string;
      secondary_background: string;
      selected: string;
      hover: string;
      font: string;
    };
    custom_checkbox: {
      selected: string;
      unselected: string;
    };
  }

  interface PaletteOptions {
    custom_background?: string;
    custom_field_background?: string;
    custom_sidebar?: {
      primary?: string;
      secondary?: string;
      background?: string;
      hover?: string;
    };
    custom_button?: {
      primary?: string;
      secondary?: string;
      primary_font?: string;
      secondary_font?: string;
    };
    custom_menu?: {
      primary_background?: string;
      secondary_background?: string;
      selected?: string;
      hover?: string;
      font?: string;
    };
    custom_checkbox?: {
      selected?: string;
      unselected?: string;
    };
  }

  interface Theme {
    sizes: {
      mobile: {
        marginTop: string;
      };
    };
  }

  interface ThemeOptions {
    sizes?: {
      mobile?: {
        marginTop?: string;
      };
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#59C1E6",
    },
    secondary: {
      main: "#F5F5F5",
    },
    custom_sidebar: {
      primary: "#B3E5FC",
      secondary: "#F5F5F5",
      background: "#2C2C34",
      hover: "#11111F",
    },
    custom_button: {
      primary: "#59C1E6",
      secondary: "#F5F5F5",
      primary_font: "#000000",
      secondary_font: "#FFFFFF",
    },
    custom_menu: {
      primary_background: "#FFFFFF",
      secondary_background: "#F5F5F5",
      selected: "#B3E5FC",
      hover: "#E0F7FA",
      font: "#000000",
    },
    custom_checkbox: {
      selected: "#2C2C34",
      unselected: "#2C2C34",
    },
    custom_background: "#F5F5F5",
    custom_field_background: "#ECEFF1"
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  sizes: {
    mobile: {
      marginTop: '48px'
    }
  }
});

export default theme;
